<div ngbDropdown #myDrop="ngbDropdown" class="d-none d-lg-inline-block mr-3" display="dynamic" [autoClose]="true" (openChange)="search($event, 0)">
  <div class="cursor-pointer account-container text-white d-flex align-items-center" ngbDropdownToggle>
    <div class="ml-2">Equipment Rentals</div>
    <span class="caret icon-carrot" style="color: white; font-size: 10px"></span>
  </div>

  <div ngbDropdownMenu class="account-dropdown fs-15">
    <div class="arrow-up"></div>
    <ng-template [ngTemplateOutlet]="currentSelectedDropdown"></ng-template>
  </div>
</div>
<!--  buy it now -->
<div ngbDropdown #myDrop1="ngbDropdown" class="d-none d-lg-inline-block mr-3" display="dynamic" [autoClose]="true" (openChange)="search($event, 1)">
  <div class="cursor-pointer account-container text-white d-flex align-items-center" ngbDropdownToggle>
    <div class="ml-2">Buy it Now</div>
    <span class="caret icon-carrot" style="color: white; font-size: 10px"></span>
  </div>

  <div ngbDropdownMenu class="account-dropdown fs-15">
    <div class="arrow-up"></div>
    <ng-template [ngTemplateOutlet]="currentSelectedDropdown"></ng-template>
  </div>
</div>

<!-- rent to own -->
<div ngbDropdown #myDrop2="ngbDropdown" class="d-none d-lg-inline-block mr-3" display="dynamic" [autoClose]="true" (openChange)="search($event, 2)">
  <div class="cursor-pointer account-container text-white d-flex align-items-center" ngbDropdownToggle>
    <div class="ml-2">Rent to Own</div>
    <span class="caret icon-carrot" style="color: white; font-size: 10px"></span>
  </div>

  <div ngbDropdownMenu class="account-dropdown fs-15">
    <div class="arrow-up"></div>
    <ng-template [ngTemplateOutlet]="currentSelectedDropdown"></ng-template>
  </div>
</div>

<ng-template #currentSelectedDropdown>
  <div class="p-4">
    <h4 class="mb-4">{{ title }}</h4>
    <div class="card-columns">
      <div class="card border-0" *ngFor="let category of popularMachineCategoryData">
        <div class="d-flex justify-content-between">
          <a
            class="font-weight-light text-dark"
            [routerLink]="['equipment-rentals', category.header]"
            [queryParams]="machineCardParams(category.queryParams)"
            >{{ category.header }}</a
          >
          <span class="text-grey">({{ category.subHeader }})</span>
        </div>
      </div>
    </div>
    <a class="btn-outline-success btn br-5" [routerLink]="['equipment-rentals']" [queryParams]="queryParams">View All Categories</a>
  </div>
</ng-template>
