import { Component, OnInit, ViewChild } from "@angular/core";
import { AzureSearchRequest, AzureSearchService } from "../../../azure-search/azure-search";
import { InventoryItem } from "../../../inventory/inventory";
import { filter, forkJoin } from "rxjs";
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: "common-header-left-side",
  templateUrl: "./header-left-side.component.html",
  styleUrls: ["./header-left-side.component.scss"],
})
export class HeaderLeftSideComponent implements OnInit {
  baseFilter: string =
    "(status eq 'Available' or status eq 'Onboarding') and " +
    "(rentalRate ne 0) and " +
    "(requiresAdminApproval eq false) and " + // we can get rid of this in a few days
    "(approvalStatus eq 'Approved' or approvalStatus eq null)";

  renterNavigationItem: any[] = [
    {
      label: "All Rentals",
      title: "Popular Rental Categories",
      queryParams: { radius: "anywhere" },
    },
    {
      label: "Buy it Now",
      title: "Popular Buy it Now Categories",
      filter: " and (buyItNowEnabled eq true)",
      queryParams: { buyItNowEnabled: true, radius: "anywhere" },
    },
    {
      label: "Rent to Own",
      title: "Popular Rent to Own Categories",
      filter: " and (rpoEnabled eq true)",
      queryParams: { rpoEnabled: true, radius: "anywhere" },
    },
  ];

  selectedTitle: string;
  queryParams: any;
  popularMachineCategoryData: any[];
  title: any;
  subscription: any;

  @ViewChild("myDrop") myDrop: any;
  @ViewChild("myDrop1") myDrop1: any;
  @ViewChild("myDrop2") myDrop2: any;

  constructor(private azureSearchService: AzureSearchService, private router: Router) {}

  ngOnInit(): void {
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(result => {
      this.myDrop.close();
      this.myDrop1.close();
      this.myDrop2.close();
    });
  }

  search(open: boolean, indexForSearch: number) {
    if (!open) {
      return; // since its open change event we dont care if its closing
    }

    // todo add search results caching

    this.title = this.renterNavigationItem[indexForSearch].title;
    this.queryParams = this.renterNavigationItem[indexForSearch].queryParams;

    const azureSearchRequest = new AzureSearchRequest();
    azureSearchRequest.search = "*";
    azureSearchRequest.top = 0;
    azureSearchRequest.filter = indexForSearch == 0 ? this.baseFilter : this.baseFilter + this.renterNavigationItem[indexForSearch].filter;
    azureSearchRequest.facets = ["primaryType,count:50", "indexKey, count:1000, sort:value"];

    const machineInventoryIndex$ = this.azureSearchService.search<InventoryItem>(azureSearchRequest, "machines");

    forkJoin([machineInventoryIndex$]).subscribe(result => {
      let inventoryPrimaryTypes = result[0].search.facets.primaryType;
      const data = [];

      // Sort inventoryPrimaryTypes by count in descending order
      inventoryPrimaryTypes.sort((a, b) => b.count - a.count);
      // Iterate over sorted inventoryPrimaryTypes
      for (const inventoryPrimaryType of inventoryPrimaryTypes) {
        // Find the corresponding machine type definition
        // Push the data for each primary type

        if (data.length < 26) {
          const dataItem = {
            header: `${inventoryPrimaryType.value}`,
            subHeader: `${inventoryPrimaryType.count}`,
            route: "equipment-rentals",
          };

          data.push(dataItem);
        }
      }

      this.popularMachineCategoryData = data;
      console.log(this.popularMachineCategoryData);
    });
  }

  machineCardParams(machineCardParams: any) {
    return {
      ...machineCardParams,
      ...this.queryParams,
    };
  }
}
