import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { RoutesModule } from "./routes/routes.module";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";
import { LayoutComponent } from "./layout/layout.component";
import { LayoutModule } from "@rubbl-layout";
import { CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { RubblFormsModule } from "@rubbl-forms";

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [BrowserModule, BrowserAnimationsModule, RoutesModule, SharedModule, FormsModule, LayoutModule, RubblFormsModule],
  providers: [DatePipe, DecimalPipe, CurrencyPipe, { provide: "googleTagManagerId", useValue: "bigdawg" }, { provide: "googleTagManagerAuth", useValue: "" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
