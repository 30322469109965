<div class="container home-search-container pb-1">
  <h1 class="jumbotron-header text-white text-center aca">{{ currentHeadline }}</h1>
  <form class="form home-search-form" (ngSubmit)="search()">
    <div class="row no-gutters align-items-end">
      <div class="form-group col-md-6 px-0" [class.search-suggests-active]="inputFocused">
        <label class="machine-type-label">Machine Type</label>
        <div class="keyword-container">
          <i class="icon-crawler-excavator text-grey" (click)="homeSearchInput.focus()"></i>
          <input
            type="text"
            class="form-control border-0 main-search-control input-focus-border"
            id="machineType"
            autocomplete="off"
            placeholder="Search makes, models, or keywords"
            name="searchterm"
            #homeSearchInput
            [(ngModel)]="searchRequest.searchTerm"
            (focus)="inputFocused = true"
            (blur)="inputFocused = false"
          />
          <span class="focus-border"></span>
          <app-search-suggests [searchInput]="homeSearchInput" (suggestionSelected)="suggestionSelected($event)"> </app-search-suggests>
        </div>
      </div>
      <div class="form-group ml-md-2 col-md-4 form-group-border-0">
        <label for="location">Location</label>
        <common-location-typeahead
          [location]="searchRequest.location.location"
          width="100%"
          (locationSelected)="locationSelected($event)"
          [showFocusBorder]="true"
        ></common-location-typeahead>
      </div>
      <div class="form-group col-lg-2 ml-md-3 col-md-5 search-button-group mt-4">
        <button type="submit" class="btn btn-primary btn-search form-control border-0">Search</button>
      </div>
    </div>
  </form>
</div>
