import { RangeSlider } from "../range-slider.model";
import { CountValueFacetItem, Facet } from "./facet.model";

export class SliderFacet extends Facet {
  unit: string;
  step: number;
  slider: RangeSlider;

  constructor(name: string, unit: string, step: number, facets: CountValueFacetItem[]) {
    super(name, facets);

    this.unit = unit;
    this.step = step;
    this.slider = new RangeSlider(unit, step, +this.minValue, +this.maxValue);
  }

  get selected(): boolean {
    return this.slider.sliderSelected;
  }

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);
    if (!param) {
      this.slider = new RangeSlider(this.unit, this.step, +this.minValue, +this.maxValue);
      return null;
    }

    const [floor, min, max, ceil] = param.split(",");
    this.slider.minValue = +min;
    this.slider.maxValue = +max;
    this.slider.floor = +floor;
    this.slider.ceil = +ceil;

    // if slider is not changed do not create a filter for it
    if (this.slider.ceil === this.slider.maxValue && this.slider.floor === this.slider.minValue) {
      return null;
    }

    return `(${this.name} gt ${this.slider.minValue} or ${this.name} eq ${this.slider.minValue})
           and (${this.name} lt ${this.slider.maxValue} or ${this.name} eq ${this.slider.maxValue})`;
  }

  update = (facets: CountValueFacetItem[]) => {
    // if this facet is selected do not update
    if (this.slider.sliderSelected) {
      return;
    }

    this.facets = facets;
    this.slider = new RangeSlider(this.unit, this.step, this.roundDownToNearestThousand(+this.minValue), this.roundDownToNearestThousand(+this.maxValue));
  };

  // since we are using a range we need to reset the min and max value
  // this is technically unchecking a facet
  uncheck = () => {
    this.slider.minValue = this.slider.floor;
    this.slider.maxValue = this.slider.ceil;
  };

  roundDownToNearestThousand(num: number): number {
    return num < 1000 ? num : Math.floor(num / 1000) * 1000;
  }

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    return `${this.slider.floor},${this.slider.minValue},${this.slider.maxValue},${this.slider.ceil}`;
  };
}
