import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { Observable, Subject, merge, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from "rxjs/operators";
import { HttpParams, HttpClient } from "@angular/common/http";
import { LocationTypeahead } from "./location-typeahead.model";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { EnvironmentConfig } from "../../models/environment-config.model";
import { Location } from "../../models/location.model";
import { Address } from "../../models/address.model";
import { LocalStorageService } from "../../services/local-storage.service";
import { AzureMapService } from "../../../azure-maps/azure-maps";

declare var environment: EnvironmentConfig;
@Component({
  selector: "common-location-typeahead",
  templateUrl: "./location-typeahead.component.html",
  styleUrls: ["./location-typeahead.component.scss"],
})
export class LocationTypeaheadComponent implements OnChanges {
  @ViewChild("instance") instance: NgbTypeahead;
  @ViewChild("typeahead") typeahead: ElementRef;

  @Input() location: Location;
  @Input() placeHolderText: string = "Enter City or Zip";
  @Input() showIcon: boolean = false;
  @Input() width: string; // must set parent form-group width for this to work
  @Input() onlyAddresses: boolean = false;
  @Input() dropdownClass: string = "";
  @Input() showFocusBorder: boolean;
  @Input() inputClass: string = "";
  @Input() useApiNinja: boolean = false;

  @Output() locationSelected = new EventEmitter<Location>();

  environment: EnvironmentConfig;
  sendEvent: boolean = false;
  model: any;
  availableLocations: LocationTypeahead[];

  constructor(private http: HttpClient, private cd: ChangeDetectorRef, private httpClient: HttpClient) {
    this.environment = environment;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const locationCurrentValue = changes.location?.currentValue as Location;
    if (!locationCurrentValue?.latitude || !locationCurrentValue?.longitude) {
      this.sendEvent = true;

      // if we had a previous location and got rid of it, we need to wipe out the input
      if (changes.location?.previousValue != null) {
        this.model = null;
      }
      return;
    }

    if (locationCurrentValue.address != null) {
      // convert location from api to azure location
      this.model = {
        address: {
          freeformAddress: new Address().toString(locationCurrentValue.address),
        },
        position: {
          lat: locationCurrentValue.latitude,
          lon: locationCurrentValue.longitude,
        },
      };
      this.sendEvent = false;
    }
  }

  onEnter() {
    this.formatter(this.availableLocations[0]);
  }

  formatter = (selectedTypeahead: LocationTypeahead) => {
    if (selectedTypeahead == null || !selectedTypeahead?.position?.lat || !selectedTypeahead?.position?.lon) {
      return "";
    }

    // convert azure location to api location
    const address = {
      address1: `${selectedTypeahead.address.streetNumber || ""} ${selectedTypeahead.address.streetName || ""}`,
      city: selectedTypeahead.address.postalName || selectedTypeahead.address.localName || selectedTypeahead.address.municipality || "",
      stateProvince: selectedTypeahead.address.countrySubdivision || "",
      postalCode: selectedTypeahead.address.postalCode || "",
      countryCode: selectedTypeahead.address.countryCode || "",
    } as Address;

    const location = {
      latitude: selectedTypeahead.position.lat,
      longitude: selectedTypeahead.position.lon,
      name: "",
      address,
    } as Location;

    if (this.sendEvent) {
      this.locationSelected.emit(location);
    }

    this.location = location;

    this.sendEvent = true;
    this.typeahead.nativeElement.blur();
    return selectedTypeahead.address?.freeformAddress?.trim();
  };

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(1000), distinctUntilChanged());

    return merge(debouncedText$).pipe(
      switchMap(term =>
        this.searchMaps(term).pipe(
          tap((results: LocationTypeahead[]) => {
            this.availableLocations = results;
          }),
          catchError(() => {
            return of([]);
          }),
        ),
      ),
    );
  };

  focus$ = new Subject<string>();

  searchMaps(term: string): Observable<LocationTypeahead[]> {
    if (this.useApiNinja) {
      return this.geoCodeNinja(term).pipe(
        map(response => {
          return response;
        }),
      );
    }

    const params = new HttpParams({
      fromObject: {
        "api-version": "1",
        "subscription-key": this.environment.azureMapKey,
        typeahead: "true",
        query: term,
        limit: this.onlyAddresses ? "10" : "5", // 10 is default. for cities we just want 5 results
        countrySet: "US,CA",
        idxSet: this.onlyAddresses ? "PAD,Addr" : "PAD,Geo",
      },
    });

    return this.http.get(`https://atlas.microsoft.com/search/fuzzy/json`, { params }).pipe(
      map(response => {
        return response["results"].filter(d => d.entityType !== "MunicipalitySubdivision");
      }),
    );
  }

  onBlur() {
    if (!this.location) {
      this.locationSelected.emit(null);
      this.model = null;
    }

    if (this.location && this.model == "") {
      this.locationSelected.emit(null);
      this.model = null;
    }
  }

  geoCodeNinja(location: string): Observable<LocationTypeahead[]> {
    // const split = location.split("-");
    // const stateAbbr = split[split.length - 1].trim();
    // const city = split.slice(0, -1).join(" ").trim();
    // const state = this.ipGeoLocationService.getStateName(stateAbbr);
    // const country = "USA";
    const url = "https://api.api-ninjas.com/v1/geocoding";
    const headers = {
      "X-Api-Key": "y9Gc4wG/dVcHvJzMQTNj/g==YUn9qkKqfayqctNc",
    };

    const params = new HttpParams().append("city", location + "*").append("country", "US"); //.append("country", country)
    return this.httpClient.get(url, { params, headers }).pipe(
      switchMap((response: any) => {
        if (!response || response.length === 0) {
          return;
          // return this.geoCode(location);
        }

        console.log(response);

        return of([]);

        var typeaheadResults: LocationTypeahead[] = [];
        response.forEach(result => {
          const city = result.name;
          const state = result.state;
          const country = result.country;

          typeaheadResults.push({
            address: {
              freeformAddress: `${result.name}, ${result.state}`,
              streetNumber: "",
              streetName: "",
              postalName: result.name,
              localName: "",
              municipality: "",
              countrySubdivision: result.state,
              postalCode: "",
              countryCode: "US",
            },
            position: {
              lat: result.latitude,
              lon: result.longitude,
            },
          } as any);
        });

        console.log(response);
        // console.log(response);
        // const resp = new Location();
        // if (response && response.length > 0) {
        //   let bestResult = response.find(d => d.name === city && d.state === state);
        //   if (!bestResult) {
        //     this.logger.trackEvent("geoCodeNinja-best-result-fail");
        //     return this.geoCode(location);
        //   }

        //   this.logger.trackEvent("geoCodeNinja-best-result-success");
        //   resp.latitude = bestResult.latitude;
        //   resp.longitude = bestResult.longitude;
        //   resp.address.city = bestResult.name;
        //   resp.address.stateProvince = this.ipGeoLocationService.getStateCode(bestResult.state);
        //   resp.address.country = bestResult.country;
        //   resp.name = `${resp.address.city}, ${resp.address.stateProvince}`;
        // }
        return of(response);
      }),
      // catchError(error => {
      //   return this.geoCode(location);
      // }),
    );
  }
}
