import { Component, OnDestroy, OnInit } from "@angular/core";
import { AzureSuggestsResult } from "../../../azure-search/models/azure-suggests-result.model";
import { SearchRequest, SearchResultsService } from "../../../search-results/search-results";
import { Location, LogService, MarketTrackingEvents } from "../../../core/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header-search",
  templateUrl: "./header-search.component.html",
  styleUrls: ["./header-search.component.scss"],
})
export class HeaderSearchComponent implements OnInit, OnDestroy {
  searchRequest: SearchRequest;
  sub: Subscription;

  constructor(private router: Router, private searchResultsService: SearchResultsService, private logService: LogService) {}

  ngOnInit() {
    this.searchRequest = this.searchResultsService.searchRequest;
    this.sub = this.searchResultsService.searchRequest$.subscribe(searchRequest => {
      this.searchRequest = searchRequest == null ? new SearchRequest() : searchRequest;
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  suggestionSelected($event: AzureSuggestsResult) {
    this.searchRequest.searchTerm = $event.value;
    this.searchRequest.selectedSuggestion = $event;

    this.onEnter();
  }

  locationSelected(event: Location) {
    this.searchRequest.location.location = event;
    // store this so we can look up gps coordinates
    this.searchResultsService.storeLocation(event);

    this.onEnter();
  }

  useMyLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.searchRequest.location.location = new Location();
          this.searchRequest.location.location.address.address1 = "Current Location";
          this.searchRequest.location.location.latitude = position.coords.latitude;
          this.searchRequest.location.location.longitude = position.coords.longitude;

          this.onEnter();
        },
        error => {
          this.searchRequest.location.location = null;
          this.onEnter();
          if (error.code === error.PERMISSION_DENIED) {
            // alert("You denied location access. Please enable location access to use this feature.");
          }
        },
      );
      return;
    }
  }

  onEnter() {
    this.router.navigate([this.searchRequest.searchResultsUrl()], { queryParams: this.searchRequest.queryParams });
    this.logService.trackEvent(
      MarketTrackingEvents.Search.Header,
      {
        event: "rentalSearch",
        searchTerm: this.searchRequest.searchTerm,
        location: this.searchRequest.location.toCityState(),
      },
      true,
    );
  }
}
